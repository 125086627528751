import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import About from "./about/About";
import Contact, { contactAction } from "./help/Contact";
import Faq from "./help/Faq";
import Home from "./home/Home";
import NotFound from "./notFound/NotFound";
import Careers from "./careers/Careers";
import CareerDetails, { careerDetailsLoader } from "./careers/CareerDetails";
import { careersLoader } from "./careers/Careers";
import CareersError from "./careers/CareersError";

import HelpLayout from "./layouts/HelpLayout";
import RootLayout from "./layouts/RootLayout";
import CareersLayout from "./layouts/CareersLayout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />}></Route>
      <Route path="about" element={<About />}></Route>
      {/* NESTED LAYOUTS */}
      <Route path="help" element={<HelpLayout />}>
        <Route path="faq" element={<Faq />}></Route>
        <Route
          path="contact"
          element={<Contact />}
          action={contactAction}
        ></Route>
      </Route>

      <Route
        path="careers"
        element={<CareersLayout />}
        errorElement={<CareersError />}
      >
        <Route index element={<Careers />} loader={careersLoader}></Route>
        {/* Bevor CareerDetails gerendert wird, wird der Loader ausgeführt */}
        <Route
          path=":id"
          element={<CareerDetails />}
          loader={careerDetailsLoader}
        ></Route>
      </Route>

      {/* VERWEIS AUF DIE 404 Page */}
      <Route path="*" element={<NotFound />}></Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

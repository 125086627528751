// rfc

import { NavLink, Outlet } from "react-router-dom";

export default function HelpLayout() {
  return (
    <div className="help-layout">
      <h2>Website Help</h2>
      <p>
        Ut consequat ad consequat sunt aute sunt duis laborum eiusmod dolor
        proident culpa. Tempor excepteur laboris enim quis irure reprehenderit
        consectetur aute nulla excepteur ad qui. Ut ipsum mollit sunt cillum
        voluptate eu. Et duis adipisicing culpa ea in ad enim est magna fugiat
        nulla. Exercitation dolor quis consequat ex id pariatur ea officia
        deserunt veniam occaecat laborum occaecat. Minim cupidatat laborum quis
        qui elit excepteur ipsum cupidatat enim. Consectetur sint incididunt
        incididunt culpa et duis quis in incididunt fugiat. Lorem reprehenderit
        aliquip elit consectetur dolore aliqua esse veniam ullamco qui cillum
        sit officia. Velit minim adipisicing sint nisi consequat sunt labore
        pariatur fugiat enim.
      </p>

      <nav>
        <NavLink to="faq">View the FAQ</NavLink>
        <NavLink to="contact">Contact us</NavLink>
      </nav>
      <Outlet></Outlet>
    </div>
  );
}

import React from "react";
import { useParams, useLoaderData } from "react-router-dom";

export default function CareerDetails() {
  // TODO Parameter muss der Angabe im aufrufenden Element entsprechen
  //   Parameter-Hook nimmt Parameter entgegen
  const { id } = useParams();
  const career = useLoaderData();

  return (
    <div className="career-details">
      <h2>CareerDetails for {career.title}</h2>
      <p>Starting salary {career.salary}</p>
      <p>Location: {career.location}</p>
      <div className="details">
        <p>
          Labore ut aliqua excepteur nulla. Qui elit proident magna officia.
          Cillum minim commodo deserunt nisi pariatur proident irure non ex
          ipsum. Lorem ut veniam consequat nulla cupidatat esse ea nostrud.
          Ullamco proident quis reprehenderit veniam duis cillum adipisicing
          officia consectetur. Eu nostrud eu ut quis nisi anim. Irure aliquip
          deserunt id exercitation reprehenderit labore minim culpa amet. Amet
          esse duis magna in amet occaecat. Labore nulla fugiat mollit nisi est
          commodo. Consequat elit qui sint incididunt. Esse ea do ullamco
          excepteur ex id.
        </p>
      </div>
    </div>
  );
}

// params ist dem ensprechend, wan bei dem Aufruf von Career Details erfolgt
export const careerDetailsLoader = async ({ params }) => {
  const { id } = params;

  const res = await fetch("http://localhost:4000/careers/" + id);
  
  // TODO ERROR-Handing
  if (!res.ok) {
    throw Error("Could not find the career");
  }

  return res.json();
};

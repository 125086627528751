import React from "react";
import { useLoaderData, Link } from "react-router-dom";

export default function Careers() {
  const careers = useLoaderData();

  return (
    <div className="careers">
      {careers.map((career) => (
        <Link to={career.id.toString()} key={career.id}>
          <p>{career.title}</p>
          <p>Based in {career.location}</p>
        </Link>
      ))}
    </div>
  );
}

// TODO Loader Function
// Fetch Data from a data source
// Normalerweise Effect-Hook
// JSON-Server starten
export const careersLoader = async () => {
  // Call from an API (.json)
  const res = await fetch("http://localhost:4000/careers");

  // TODO ERROR-Handing
  if (!res.ok) {
    throw Error("Could not find the career");
  }

  // Promise
  return res.json();
};

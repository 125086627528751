import React from "react";
import { Form, redirect, useActionData } from "react-router-dom";
export default function Contact() {
  // Zugriff auf den RÜckgabewert (Object) der Funktions außerhalb der Komponente
  const data = useActionData();

  return (
    <div className="contact">
      <h3>Contact Us</h3>
      {/* method durch action kann nachverfolgt werden, welche method verwendet wurde
      Action => Wird gesendet, wenn das Formular submitted wird */}
      <Form method="post" action="/help/contact">
        <label>
          <span>Your email:</span>
          <input type="email" name="email" required />
        </label>
        <label>
          <span>Your message:</span>
          <textarea name="message" required></textarea>
        </label>
        <button type="submit">Submit</button>

        {data && data.error && <p>{data.error}</p>}
      </Form>
    </div>
  );
}

// request enthält alle Data des Formulars
// Asynchroner Aufruf
export const contactAction = async ({ request }) => {
  console.log(request);
  // await= Es wird gewartet bis alle Daten geladen wurden
  const data = await request.formData();

  const submission = {
    email: data.get("email"),
    message: data.get("message"),
  };

  console.log(submission);
  if (submission.message.length < 10) {
    return { error: "Message must be over 10 chars long" };
  }
  // redirect user
  return redirect("/");
};

import React from "react";

export default function Faq() {
  return (
    <div className="faq">
      <h3>Frquently Asked Questions</h3>
      <div className="question">
        <p>
          <strong>Pariatur minim duis anim consequat.</strong>
        </p>
        <p>
          Ut cupidatat eu consectetur quis ea exercitation. Proident duis
          ullamco qui amet Lorem magna consequat. Deserunt velit sit aute
          consequat commodo culpa anim nisi dolor non. Qui duis non ut enim
          Lorem sit incididunt culpa pariatur in consequat sunt tempor.
          Exercitation et labore commodo laborum ut. Esse ullamco elit velit
          consequat labore do aute officia ex dolore exercitation enim irure.
          Anim pariatur tempor in proident do consectetur cillum deserunt eu
          laboris reprehenderit ullamco eu. Et cillum tempor cupidatat ut eu
          commodo magna qui. Velit officia aliqua nulla adipisicing adipisicing
          exercitation adipisicing officia quis ut.
        </p>
      </div>
      <div className="question">
        <p>
          <strong>Pariatur minim duis anim consequat.</strong>
        </p>
        <p>
          Ut cupidatat eu consectetur quis ea exercitation. Proident duis
          ullamco qui amet Lorem magna consequat. Deserunt velit sit aute
          consequat commodo culpa anim nisi dolor non. Qui duis non ut enim
          Lorem sit incididunt culpa pariatur in consequat sunt tempor.
          Exercitation et labore commodo laborum ut. Esse ullamco elit velit
          consequat labore do aute officia ex dolore exercitation enim irure.
          Anim pariatur tempor in proident do consectetur cillum deserunt eu
          laboris reprehenderit ullamco eu. Et cillum tempor cupidatat ut eu
          commodo magna qui. Velit officia aliqua nulla adipisicing adipisicing
          exercitation adipisicing officia quis ut.
        </p>
      </div>
      <div className="question">
        <p>
          <strong>Pariatur minim duis anim consequat.</strong>
        </p>
        <p>
          Ut cupidatat eu consectetur quis ea exercitation. Proident duis
          ullamco qui amet Lorem magna consequat. Deserunt velit sit aute
          consequat commodo culpa anim nisi dolor non. Qui duis non ut enim
          Lorem sit incididunt culpa pariatur in consequat sunt tempor.
          Exercitation et labore commodo laborum ut. Esse ullamco elit velit
          consequat labore do aute officia ex dolore exercitation enim irure.
          Anim pariatur tempor in proident do consectetur cillum deserunt eu
          laboris reprehenderit ullamco eu. Et cillum tempor cupidatat ut eu
          commodo magna qui. Velit officia aliqua nulla adipisicing adipisicing
          exercitation adipisicing officia quis ut.
        </p>
      </div>
    </div>
  );
}

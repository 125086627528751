import { useState } from "react";
import { Navigate } from "react-router-dom";

export default function About() {
  const [user, setUser] = useState("Mario");

  if (!user) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="about">
      <h2>About</h2>
      <p>
        Cupidatat adipisicing culpa consequat ipsum consectetur non dolor culpa.
        Voluptate consectetur anim exercitation quis duis id cupidatat. Id
        nostrud commodo consequat irure et ea deserunt aliqua qui commodo
        consequat incididunt laboris. Reprehenderit cillum minim laborum fugiat
        do voluptate commodo est ipsum dolore ex deserunt est aliqua. Pariatur
        qui nulla nostrud sint sunt consequat ad elit laboris amet deserunt in
        proident in. Labore reprehenderit sit exercitation in eu cupidatat ipsum
        velit cillum. Enim labore consequat sit consectetur. Laboris velit enim
        amet tempor cupidatat ea sint occaecat culpa ea. Aute consequat mollit
        in ipsum. Consequat dolore velit pariatur qui qui ullamco cupidatat duis
        nulla. Reprehenderit ad excepteur nostrud culpa elit adipisicing nisi
        consequat mollit.
      </p>
      <p>
        Cupidatat adipisicing culpa consequat ipsum consectetur non dolor culpa.
        Voluptate consectetur anim exercitation quis duis id cupidatat. Id
        nostrud commodo consequat irure et ea deserunt aliqua qui commodo
        consequat incididunt laboris. Reprehenderit cillum minim laborum fugiat
        do voluptate commodo est ipsum dolore ex deserunt est aliqua. Pariatur
        qui nulla nostrud sint sunt consequat ad elit laboris amet deserunt in
        proident in. Labore reprehenderit sit exercitation in eu cupidatat ipsum
        velit cillum. Enim labore consequat sit consectetur. Laboris velit enim
        amet tempor cupidatat ea sint occaecat culpa ea. Aute consequat mollit
        in ipsum. Consequat dolore velit pariatur qui qui ullamco cupidatat duis
        nulla. Reprehenderit ad excepteur nostrud culpa elit adipisicing nisi
        consequat mollit.
      </p>
      <p>
        Cupidatat adipisicing culpa consequat ipsum consectetur non dolor culpa.
        Voluptate consectetur anim exercitation quis duis id cupidatat. Id
        nostrud commodo consequat irure et ea deserunt aliqua qui commodo
        consequat incididunt laboris. Reprehenderit cillum minim laborum fugiat
        do voluptate commodo est ipsum dolore ex deserunt est aliqua. Pariatur
        qui nulla nostrud sint sunt consequat ad elit laboris amet deserunt in
        proident in. Labore reprehenderit sit exercitation in eu cupidatat ipsum
        velit cillum. Enim labore consequat sit consectetur. Laboris velit enim
        amet tempor cupidatat ea sint occaecat culpa ea. Aute consequat mollit
        in ipsum. Consequat dolore velit pariatur qui qui ullamco cupidatat duis
        nulla. Reprehenderit ad excepteur nostrud culpa elit adipisicing nisi
        consequat mollit.
      </p>
      <button onClick={() => setUser(null)}> Logout</button>
    </div>
  );
}

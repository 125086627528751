export default function Home() {
  return (
    <div className="home">
      <h2>Welcome</h2>
      <p>
        Id cillum cillum sit sunt. Voluptate aute sit enim velit ad adipisicing
        id culpa pariatur nulla nisi non exercitation. Cillum anim fugiat dolor
        et consequat in ullamco. Et nisi amet qui non aliquip elit elit labore.
        Mollit adipisicing et cupidatat adipisicing laborum ipsum in. Deserunt
        culpa consectetur qui et. Non nulla aliquip occaecat dolore laborum
        deserunt reprehenderit laboris labore ut mollit adipisicing. Cupidatat
        sit duis excepteur occaecat do dolore aute. Ullamco occaecat irure aute
        Lorem aliqua esse veniam exercitation ipsum incididunt velit sit
        reprehenderit reprehenderit. Ea velit consequat duis exercitation dolor
        cupidatat laboris officia reprehenderit. Exercitation Lorem proident
        aliqua incididunt voluptate esse. Qui quis enim cillum consectetur est
        pariatur et consectetur. Minim velit eu adipisicing tempor adipisicing
        reprehenderit exercitation. Exercitation est nostrud voluptate occaecat
        et sint consectetur aute laboris dolore adipisicing sint non aliqua.
      </p>
      <p>
        Id cillum cillum sit sunt. Voluptate aute sit enim velit ad adipisicing
        id culpa pariatur nulla nisi non exercitation. Cillum anim fugiat dolor
        et consequat in ullamco. Et nisi amet qui non aliquip elit elit labore.
        Mollit adipisicing et cupidatat adipisicing laborum ipsum in. Deserunt
        culpa consectetur qui et. Non nulla aliquip occaecat dolore laborum
        deserunt reprehenderit laboris labore ut mollit adipisicing. Cupidatat
        sit duis excepteur occaecat do dolore aute. Ullamco occaecat irure aute
        Lorem aliqua esse veniam exercitation ipsum incididunt velit sit
        reprehenderit reprehenderit. Ea velit consequat duis exercitation dolor
        cupidatat laboris officia reprehenderit. Exercitation Lorem proident
        aliqua incididunt voluptate esse. Qui quis enim cillum consectetur est
        pariatur et consectetur. Minim velit eu adipisicing tempor adipisicing
        reprehenderit exercitation. Exercitation est nostrud voluptate occaecat
        et sint consectetur aute laboris dolore adipisicing sint non aliqua.
      </p>
      <p>
        Id cillum cillum sit sunt. Voluptate aute sit enim velit ad adipisicing
        id culpa pariatur nulla nisi non exercitation. Cillum anim fugiat dolor
        et consequat in ullamco. Et nisi amet qui non aliquip elit elit labore.
        Mollit adipisicing et cupidatat adipisicing laborum ipsum in. Deserunt
        culpa consectetur qui et. Non nulla aliquip occaecat dolore laborum
        deserunt reprehenderit laboris labore ut mollit adipisicing. Cupidatat
        sit duis excepteur occaecat do dolore aute. Ullamco occaecat irure aute
        Lorem aliqua esse veniam exercitation ipsum incididunt velit sit
        reprehenderit reprehenderit. Ea velit consequat duis exercitation dolor
        cupidatat laboris officia reprehenderit. Exercitation Lorem proident
        aliqua incididunt voluptate esse. Qui quis enim cillum consectetur est
        pariatur et consectetur. Minim velit eu adipisicing tempor adipisicing
        reprehenderit exercitation. Exercitation est nostrud voluptate occaecat
        et sint consectetur aute laboris dolore adipisicing sint non aliqua.
      </p>
    </div>
  );
}

import React from "react";
import { Outlet } from "react-router-dom";

export default function CareersLayout() {
  return (
    <div className="careers-layout">
      <h2>Careers</h2>
      <p>
        Cillum reprehenderit ea nisi reprehenderit est mollit ex dolor Lorem
        consequat enim. Est ad qui voluptate Lorem cillum et culpa ullamco.
        Voluptate laboris sit do eu non sit est ea. Officia anim esse deserunt
        ea sit est aliqua anim ipsum reprehenderit sint aliquip laboris. Aliquip
        aliquip minim laboris in commodo mollit non exercitation reprehenderit
        minim dolor incididunt dolor dolor. Dolor enim labore ea anim nulla
        cillum ipsum est. Sit cillum id ullamco proident qui. Pariatur labore et
        voluptate elit. Pariatur aliqua anim ad nisi sunt aliquip occaecat sit
        non exercitation. In elit adipisicing in id eiusmod reprehenderit amet
        voluptate et ex minim minim mollit eu. Proident consequat sit aliquip
        nulla do voluptate id cupidatat in Lorem sunt aliqua officia.
      </p>
      <Outlet></Outlet>
    </div>
  );
}
